<template src="./status.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue'
import draggable from 'vuedraggable'

export default {
  name: "Status",
  components: {draggable, 'tinymceEditor': Editor},
  data: function () {
    return {
      types: [
        'Anbahnung',
        'Ausbildung',
        'Abgeschlossen',
      ],
      states: [],
    }
  },
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  created: function(){
    let self = this;

    Http.request('GET', '/customers/states').then(function (response) {
      let states = {};
      if(response.data.length > 0) {
        response.data.forEach(x => {
          if (typeof states[x.is_customer] === 'undefined') {
            states[x.is_customer] = [];
          }
          states[x.is_customer].push(x);
        });
        self.states = states;
      }
    });
  },
  methods: {
    addStatus (index, type) {
      let self = this;
      self.states[index].splice(type.length - 1, 0, { id: null, name: '', is_customer: index, customer_count:0 })
    },
    deleteStatus (state, type, key) {
      let self = this;
      self.$bvModal.msgBoxConfirm("Möchstest du diesen Status wirklich löschen?", {
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
        .then(answer => {
          if(answer) {
            if(state.id > 0){
              Http.request('DELETE', '/customers/states/' + state.id).then(function () {
                self.states[type].splice(key, 1)
              });
            }
            else {
              self.states[type].splice(key, 1)
            }
          }
        });
    },
    saveSort (index) {
      Http.request('POST', '/customers/states/sort', { data: this.states[index] });
    },
    statusMove(evt, originalEvent){
      if(
        evt.draggedContext.index === 0 ||
        evt.draggedContext.futureIndex === 0 ||
        evt.draggedContext.index + 1 === this.states[evt.draggedContext.element.is_customer].length ||
        evt.draggedContext.futureIndex + 1 === this.states[evt.draggedContext.element.is_customer].length
      ){
        return false;
      }
    },
    save(state, index, index2){
      let self = this;
      Http.request('POST', '/customers/states/' + state.id, state).then((response) => {
        if(state.id === null){
          self.states[index][index2] = response.data;
          self.saveSort(index);
        }
        self.$bvToast.toast('Status gespeichert', {
          title: 'Gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    },
  }
}
</script>

<style scoped></style>
